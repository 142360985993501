import {
  formatCurrency, formatDate, formatNumber, formatPercent,
} from '@angular/common';

import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

export type MappingType = 'string' | 'number' | 'boolean' | 'date' | 'time' |
  'localdatetime' | 'enum' | 'float' | 'currency' | 'percent' | 'array';

export interface Mapping<T> {
  remote: string;
  local: keyof T;

  type?: MappingType;
  optional?: boolean;
  optionalEmpty?: boolean;

  enumPairs?: Array<{ id: number, name: string }>;

  human?: string;
  humanIgnored?: boolean;
}

const PLAIN_DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;
const TIME_REGEX = /^\d{2}:\d{2}(:\d{2})?/

export class Mapper<T extends object> {

  constructor(private readonly mappings: Array<Mapping<T>>) { }

  serialize(source: T): object {
    const attributes = this.mappings.map(mapping => {
      if (!(mapping.local in source) || source[mapping.local] === null) {
        if (!mapping.optionalEmpty) {
          return { [mapping.remote]: null };
        }
        return null;
      }

      return { [mapping.remote]: this.serializeValue(source, mapping) };
    });

    return Object.assign({}, ...attributes);
  }

  serializeValue(source: T, mapping: Mapping<T>): any {
    const value: any = source[mapping.local];
    switch (mapping.type) {
      case 'currency':
      case 'float':
      case 'percent':
        return (value as number).toString();

      case 'localdatetime': {
        if (value === '' || value === undefined || Number.isNaN(value.valueOf()))
          return null;
        const date = new Date(value);
        return date.toISOString();
      }

      case 'time': {
        if (value instanceof Date)
          return `${value.getHours()}:${value.getMinutes()}`;
        else
          return value;
      }
      default:
        return value;
    }
  }

  serializeAll(source: T[]): object {
    return source.map(item => this.serialize(item));
  }

  deserialize(data: unknown): T {
    if (typeof data !== 'object' || !data) {
      throw new Error('data is not an object');
    }

    const attributes = this.mappings.map((mapping) => {
      if (!(mapping.remote in data)) {
        if (mapping.optional) {
          return null;
        }
        throw new Error('field does not exist: ' + mapping.remote);

      } else if ((data as any)[mapping.remote] === null) {
        if (mapping.optional) {
          return { [mapping.local]: null };
        }
        throw new Error('field does not exist: ' + mapping.remote);

      }
      return { [mapping.local]: this.deserializeValue(data, mapping) };
    });

    if (!environment.production) {
      for (const unread of this.unreadKeys(data)) {
        console.warn('did not read field:', unread);
      }
    }

    return Object.assign({}, ...attributes) as T;
  }

  deserializeValue(data: any, mapping: Mapping<T>): any {
    const value: any = data[mapping.remote];

    switch (mapping.type) {
      case 'number':
        if (typeof value !== 'number') { throw mappingTypeError(mapping); }
        return value;

      case 'boolean':
        if (typeof value !== 'boolean') { throw mappingTypeError(mapping); }
        return value;

      case 'date':
        if (typeof value !== 'string' || !value.match(PLAIN_DATE_REGEX)) {
          throw mappingTypeError(mapping);
        }
        return value;

      case 'localdatetime':
        const date = new Date(value);
        if (isNaN(date.valueOf())) {
          throw mappingTypeError(mapping);
        }
        return date;

      case 'time':
        if (typeof value !== 'string') { throw mappingTypeError(mapping); }
        if (!value.match(TIME_REGEX)) { throw mappingTypeError(mapping); }
        return value;

      case 'enum':
        if (typeof value !== 'number') { throw mappingTypeError(mapping); }
        if (!mapping.enumPairs) {
          throw new Error('no enums to match');
        }
        if (!mapping.enumPairs.find(pair => pair.id === value)) {
          throw new Error(`field ${mapping.remote} has unknown enum value: [${value}]`);
        }
        return value;

      case 'currency':
      case 'float':
      case 'percent':
        if (typeof value === 'string') {
          return Number.parseFloat(value);
        } else if (typeof value !== 'number') {
          throw mappingTypeError(mapping);
        }
        return value;

      case 'array':
        if (!Array.isArray(value)) { throw mappingTypeError(mapping); }
        return value;

      case 'string':
      default:
        if (typeof value !== 'string') { throw mappingTypeError(mapping); }
        return value;
    }
  }

  deserializeAll(data: unknown): T[] {
    if (!Array.isArray(data)) {
      throw new Error('data is not an array');
    }
    return data.map(item => this.deserialize(item));
  }

  unreadKeys(data: unknown): string[] {
    if (typeof data !== 'object' || !data) {
      throw new Error('data is not an object');
    }

    const readProps = new Set(
      this.mappings.map(mapping => mapping.remote)
    );

    return Object.keys(data).filter(key => !readProps.has(key));
  }

  readonly mapDeserialize: OperatorFunction<unknown, T> =
    source => source.pipe(map(data => this.deserialize(data)));

  readonly mapDeserializeAll: OperatorFunction<unknown, T[]> =
    source => source.pipe(map(data => this.deserializeAll(data)));

  humanize(data: T): string {
    const strings: string[] = [];

    for (const mapping of this.mappings) {
      if (mapping.humanIgnored) { continue; }
      const value = data[mapping.local];
      if (value !== null && typeof value !== 'undefined') {
        const string = `${humanizePropertyName(mapping)}: "${humanizePropertyValue(value, mapping)}"`;
        strings.push(string);
      }
    }

    return strings.join(', ');
  }

}

const humanPropertyRegex = new RegExp('_', 'g');

function humanizePropertyName(mapping: Mapping<any>): string {
  if (mapping.human) {
    return mapping.human;
  }
  return mapping.remote.replace(humanPropertyRegex, ' ');
}

function humanizePropertyValue(value: any, mapping: Mapping<any>): string {
  switch (mapping.type) {
    case 'boolean':
      return value ? 'yes' : 'no';

    case 'enum':
      if (!mapping.enumPairs) {
        throw new Error('no enum pairs');
      }
      const pair = mapping.enumPairs.find(p => p.id === value);
      if (!pair) {
        throw new Error(`unexpected enum value for ${mapping.remote}: ${value}`);
      }
      return pair.name;

    case 'date':
      return formatDate(value, 'M/d/yyyy', 'en-US', 'UTC');

    case 'currency':
      return formatCurrency(Number.parseFloat(value), 'en', '$');

    case 'float':
      return formatNumber(value, 'en-US');

    case 'percent':
      return formatPercent(value / 100, 'en-US', '1.2-2');

    default:
      return value;
  }
}

function mappingTypeError<T>(mapping: Mapping<T>): Error {
  return new Error(`field '${mapping.remote}' is not a '${mapping.type}'`);
}
